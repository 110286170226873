<template>
    <div>
      <MainLayout>
        <template v-slot:toolbar>
          <!--begin::Page title-->
          <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <!--begin::Title-->
            <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Watcher - {{ watcherTitle }}</h1>
            <!--end::Title-->
          </div>
          <!--begin::Actions-->
          <div class="d-flex align-items-center gap-2 gap-lg-3 position-relative">
            <div class="m-0">
              <router-link
                :to="{ name: 'watcher.index' }"
                class="btn btn-sm btn-primary"
                v-if="watcher && 1 == 2"
              >
                Voltar
              </router-link>
            </div>
            <!--end::Filter menu-->
          </div>
          <!--end::Actions-->
          <!--end::Page title-->
        </template>

        <!-- CONTENT -->
        <!--begin::Navbar-->
        <div class="card mb-5 mb-xl-10">
          <div class="card-body pt-0 pb-0">
            <!--begin::Navs-->
            <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
              <!--begin::Nav item-->
              <li class="nav-item mt-2">
                <a
                  class="nav-link text-active-primary ms-0 me-10 py-5"
                  :class="{
                    active: currentTab === 'details'
                  }"
                  href="#"
                  @click.prevent="changeTab('details')"
                >
                  Detalhes
                </a>
              </li>
              <!--end::Nav item-->
              <!--begin::Nav item-->
              <li class="nav-item mt-2">
                <a
                  class="nav-link text-active-primary ms-0 me-10 py-5"
                  :class="{
                    active: currentTab === 'logs'
                  }"
                  href="#"
                  @click.prevent="changeTab('logs')"
                >
                  Histórico
                </a>
              </li>
              <!--end::Nav item-->
            </ul>
            <!--begin::Navs-->
          </div>
        </div>
        <!--end::Navbar-->
        <!--begin::details View-->
        <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
          <!--begin::Card header-->
          <div class="card-header cursor-pointer">
            <!--begin::Card title-->
            <div class="card-title m-0">
              <h3 class="fw-bold m-0">{{ tabTitle }}</h3>
            </div>
            <!--end::Card title-->
            <!--begin::Action-->
            <!--end::Action-->
          </div>
          <!--begin::Card header-->
          <!--begin::Card body-->
          <div class="card-body p-9">
            <template v-if="loader">
              <div class="py-5">
                <Loader size="big" />
              </div>
            </template>
            <template v-else>
              <!-- DETAILS TAB -->
              <div v-show="currentTab === 'details'">
                <!--begin::Input group-->
                <div class="row mb-7">
                  <!--begin::Label-->
                  <label class="col-lg-4 fw-semibold text-muted">ID</label>
                  <!--end::Label-->
                  <!--begin::Col-->
                  <div class="col-lg-8 fv-row">
                    <span class="fw-semibold text-gray-800 fs-6">{{ watcher.id }}</span>
                  </div>
                  <!--end::Col-->
                </div>
                <!--end::Input group-->
                <!--begin::Row-->
                <div class="row mb-7">
                  <!--begin::Label-->
                  <label class="col-lg-4 fw-semibold text-muted">Tipo</label>
                  <!--end::Label-->
                  <!--begin::Col-->
                  <div class="col-lg-8">
                    <span class="fw-semibold text-gray-800 fs-6">{{ watcher.type }}</span>
                  </div>
                  <!--end::Col-->
                </div>
                <!--end::Row-->
                <!--begin::Row-->
                <div class="row mb-7" v-if="watcher.type === 'CAMPAIGN'">
                  <!--begin::Label-->
                  <label class="col-lg-4 fw-semibold text-muted">Campanha</label>
                  <!--end::Label-->
                  <!--begin::Col-->
                  <div class="col-lg-8">
                    <span class="fw-semibold">
                      <router-link
                        :to="{ name: 'campaigns.show', params: { id: watcher.watchable.id } }"
                      >
                        {{ watcher.watchable.name }}
                      </router-link>
                    </span>
                  </div>
                  <!--end::Col-->
                </div>
                <!--end::Row-->
                <!--begin::Input group-->
                <div class="row mb-7">
                  <!--begin::Label-->
                  <label class="col-lg-4 fw-semibold text-muted">Métrica observada</label>
                  <!--end::Label-->
                  <!--begin::Col-->
                  <div class="col-lg-8 fv-row">
                    <span class="fw-semibold text-gray-800 fs-6">{{ watcher.metric }}</span>
                  </div>
                  <!--end::Col-->
                </div>
                <!--end::Input group-->
                <!--begin::Input group-->
                <div class="row mb-7">
                  <!--begin::Label-->
                  <label class="col-lg-4 fw-semibold text-muted">Regra</label>
                  <!--end::Label-->
                  <!--begin::Col-->
                  <div class="col-lg-8 fv-row">
                    <span class="fw-semibold text-gray-800 fs-6">{{ watcher.value }}</span>
                  </div>
                  <!--end::Col-->
                </div>
                <!--end::Input group-->
                <!--begin::Input group-->
                <div class="row mb-7">
                  <!--begin::Label-->
                  <label class="col-lg-4 fw-semibold text-muted">Data de criação</label>
                  <!--end::Label-->
                  <!--begin::Col-->
                  <div class="col-lg-8 fv-row">
                    <span class="fw-semibold text-gray-800 fs-6">{{ watcher.created_at | formatDate }}</span>
                  </div>
                  <!--end::Col-->
                </div>
                <!--end::Input group-->
                <!--begin::Input group-->
                <div class="row mb-7" v-if="campaignGoal">
                  <!--begin::Label-->
                  <label class="col-lg-4 fw-semibold text-muted">Meta</label>
                  <!--end::Label-->
                  <!--begin::Col-->
                  <div class="col-lg-8 fv-row">
                    <span class="fw-semibold text-gray-800 fs-6">{{ campaignGoal.value }}</span>
                  </div>
                  <!--end::Col-->
                </div>
                <!--end::Input group-->
                <!--begin::Input group-->
                <div class="row mb-7">
                  <!--begin::Label-->
                  <label class="col-lg-4 fw-semibold text-muted">Status</label>
                  <!--end::Label-->
                  <!--begin::Col-->
                  <div class="col-lg-8 fv-row">
                    <span
                      class="badge"
                      :class="{
                        'badge-primary': watcher.status === 'LOW',
                        'badge-warning': watcher.status === 'MEDIUM',
                        'badge-danger': watcher.status === 'HIGH',
                        'badge-secondary': watcher.status === 'PENDING'
                      }"
                    >
                        {{ watcher.status }}
                    </span>
                  </div>
                  <!--end::Col-->
                </div>
                <!--end::Input group-->
                <div class="d-flex flex-column flex-sm-row mt-3 justify-content-center gap-3">
                  <!-- Edit -->
                  <router-link
                    :to="{ name: 'campaigns.show', params: { id: watcher.watchable.id } }"
                    class="btn btn-primary"
                    v-if="watcher.type === 'CAMPAIGN'"
                  >
                    Voltar
                  </router-link><!-- Edit -->
                </div>
              </div> <!-- END DETAILS TAB -->
              <!-- LOGS TAB -->
              <div v-show="currentTab === 'logs'">
                <WatcherLogsSummary
                  :watcher="watcher"
                />
              </div> <!-- LOGS TAB -->
            </template>
          </div>
          <!--end::Card body-->
        </div>
        <!--end::details View-->
        <!-- END CONTENT -->
      </MainLayout>
    </div>
  </template>

<script>

import CampaignGoalsService from '@/modules/campaigns/services/campaign-goals-service'
import WatchersService from '@/modules/watchers/services/watchers-service'

import Loader from '@/components/common/Loader/Loader'
import MainLayout from '@/components/layouts/MainLayout'
import WatcherLogsSummary from '@/components/watcher-logs/WatcherLogSummary'

export default {
  components: {
    Loader,
    MainLayout,
    WatcherLogsSummary
  },
  metaInfo () {
    return {
      title: 'Observador - ' + this.watcherTitle
    }
  },
  data () {
    return {
      campaignGoals: [],
      currentTab: 'details',
      loader: false,
      watcher: ''

    }
  },
  created () {
    this.getWatcher()
  },
  computed: {
    /**
     * Campaign goal
    */
    campaignGoal () {
      return this.campaignGoals.length > 0 ? this.campaignGoals[0] : null
    },
    /**
     * Current tab title
     */
    tabTitle () {
      switch (this.currentTab) {
        case 'logs':
          return 'Histórico'
        default:
          return 'Detalhes'
      }
    },
    /**
     * Watcher title
     */
    watcherTitle () {
      if (!this.watcher) return ''
      if (this.watcher.type === 'CAMPAIGN') return this.watcher.watchable.name + ' - ' + this.watcher.metric
      return ''
    }
  },
  methods: {
    /**
     * Change current tab
     */
    changeTab (tab) {
      this.currentTab = tab
    },
    /**
     * Get campaign goals
     */
    async getCampaignGoals () {
      try {
        const goals = await CampaignGoalsService.getCampaignGoals({
          campaign_id: this.watcher.watchable.id,
          type: this.watcher.type
        })
        this.campaignGoals = goals.data
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
    },
    /**
     * Get watcher
     */
    async getWatcher () {
      this.loader = true
      try {
        const watcher = await WatchersService.getWatcher(this.$route.params.id)
        this.watcher = watcher
        this.getCampaignGoals()
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    }
  }
}
</script>
