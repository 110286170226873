import axios from '@/plugins/axios'

/**
 * Activate dashboard
 */
const activateWatcher = async (id) => {
  try {
    const response = await axios.post(`admin/watchers/${id}/activate`, {
      _method: 'PUT'
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Create watcher
 */
const createWatcher = async ({
  // eslint-disable-next-line camelcase
  watchable_id,
  type,
  metric,
  value
}) => {
  try {
    const form = {
      watchable_id,
      type,
      metric,
      value
    }
    const response = await axios.post('admin/watchers', form)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Destroy dashboard
 */
const destroyWatcher = async (id) => {
  try {
    const response = await axios.post(`admin/watchers/${id}`, {
      _method: 'DELETE'
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get watcher
 */
const getWatcher = async (id) => {
  try {
    const response = await axios.get(`admin/watchers/${id}`)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get watchers
 */
const getWatchers = async ({
  // eslint-disable-next-line camelcase
  campaign_id = null,
  page = 1
}) => {
  try {
    const params = {
      page: page
    }
    // eslint-disable-next-line camelcase
    if (campaign_id) params.campaign_id = campaign_id

    const response = await axios.get('admin/watchers', {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Create watcher
 */
const updateWatcher = async (id, {
  // eslint-disable-next-line camelcase
  watchable_id,
  type,
  metric,
  value
}) => {
  try {
    const form = {
      watchable_id,
      type,
      metric,
      value
    }
    form._method = 'PUT'
    const response = await axios.post(`admin/watchers/${id}`, form)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  activateWatcher,
  createWatcher,
  destroyWatcher,
  getWatcher,
  getWatchers,
  updateWatcher
}
