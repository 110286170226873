<template>
  <div>
    <template v-if="loader && logs.length === 0">
      <div class="text-center">
        <Loader
          :size="'big'"
        />
      </div>
    </template>
    <template v-else>
      <div class="watcher-logs-chart">
        <WatcherLogChart
          :logs="logs.data"
          :watcher="watcher"
        />
      </div>
      <div class="watcher-logs-table mt-5">
        <WatcherLogListTable
          :logs="logs.data"
          :watcher="watcher"
        />
      </div>
    </template>
  </div>
</template>

<script>

import WatcherLogsService from '@/modules/watchers/services/watcher-logs-service'

import Loader from '@/components/common/Loader/Loader'
import WatcherLogChart from '@/components/watcher-logs/WatcherLogChart'
import WatcherLogListTable from '@/components/watcher-logs/WatcherLogListTable'

export default {
  name: 'WatcherLogSummary',
  props: ['watcher'],
  components: {
    Loader,
    WatcherLogChart,
    WatcherLogListTable
  },
  data () {
    return {
      loader: false,
      logs: []
    }
  },
  created () {
    this.getLogs()
  },
  methods: {
    /**
     * Get logs
     */
    async getLogs () {
      this.loader = true
      try {
        const logs = await WatcherLogsService.getWatcherLogs({
          watcher_id: this.watcher.id
        })
        this.logs = logs
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    }
  }
}

</script>
