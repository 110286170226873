import axios from '@/plugins/axios'

/**
 * Create campaign goals
 */
const createCampaignGoals = async ({
  // eslint-disable-next-line camelcase
  campaign_id,
  metric,
  value
}) => {
  try {
    const form = {
      campaign_id: campaign_id,
      metric: metric,
      value: metric === 'CPM' ? parseInt(value * 100) : value
    }
    const response = await axios.post('admin/campaign-goals', form)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Destroy campaign goal
 */
const destroyCampaignGoal = async (id) => {
  try {
    const response = await axios.delete(`admin/campaign-goals/${id}`)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get campaign goal
 */
const getCampaignGoal = async (id) => {
  try {
    const response = await axios.get(`admin/campaign-goals/${id}`)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get campaign goals
 */
const getCampaignGoals = async ({
  // eslint-disable-next-line camelcase
  campaign_id,
  type = null
}) => {
  try {
    const response = await axios.get('admin/campaign-goals', {
      params: {
        campaign_id: campaign_id,
        type
      }
    })
    const { data } = response
    return data
  } catch (error) {
    console.log(error)
    return Promise.reject(error)
  }
}

/**
 * Update campaign goal
 */
const updateCampaignGoals = async (id, {
  // eslint-disable-next-line camelcase
  campaign_id,
  metric,
  value
}) => {
  try {
    const form = {
      campaign_id: campaign_id,
      metric: metric,
      value: metric === 'CPM' ? parseInt(value * 100) : value
    }
    form._method = 'PUT'
    const response = await axios.post(`admin/campaign-goals/${id}`, form)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  createCampaignGoals,
  destroyCampaignGoal,
  getCampaignGoal,
  getCampaignGoals,
  updateCampaignGoals
}
