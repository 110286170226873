<template>
    <div class="watcher-logs-list-table">
      <div v-if="loader && localLogs.length === 0" class="text-center">
        <Loader
          :size="'big'"
        />
      </div>
      <template v-else>
        <div class="table-responsive">
          <!--begin::Table-->
          <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_products_table">
            <!--begin::Table head-->
            <thead>
              <!--begin::Table row-->
              <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                <th class="min-w-100px">Data</th>
                <th class="min-w-70px">Valor esperado</th>
                <th class="min-w-70px">Valor</th>
                <th class="min-w-70px">Status</th>
              </tr>
              <!--end::Table row-->
            </thead>
            <!--end::Table head-->
            <!--begin::Table body-->
            <tbody class="fw-semibold text-gray-600">
              <!--begin::Table row-->
              <tr v-for="(log, index) in sortedLogs" :key="index">
                <td>
                  <span>{{ log.created_at }}</span>
                </td>
                <td>
                  <span>{{ parseExpectedValue(log.expected_value) }}</span>
                </td>
                <td>
                  <span>{{ parseValue(log.value) }}</span>
                </td>
                <td>
                  <span
                    class="badge"
                    :class="{
                      'badge-primary': log.status === 'LOW',
                      'badge-warning': log.status === 'MEDIUM',
                      'badge-danger': log.status === 'HIGH',
                      'badge-secondary': log.status === 'PENDING'
                    }"
                  >
                    {{ log.status }}
                  </span>
                </td>
              </tr>
              <!--end::Table row-->
            </tbody>
            <!--end::Table body-->
          </table>
        </div>
        <!--end::Table-->
        <div class="text-center mt-4">
          <Button
            class="btn-primary"
            :loading="loader"
            @click="loadMore"
            v-if="isLoadMoreVisible"
          >
            Carregar mais
          </Button>
        </div>
      </template>
    </div>
  </template>

<script>

import WatcherLogsService from '@/modules/watchers/services/watcher-logs-service'

import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'

export default {
  name: 'WatcherLogListTable',
  props: ['logs', 'watcher'],
  components: {
    Button,
    Loader
  },
  data () {
    return {
      localLogs: [],
      loader: false,
      page: 1,
      isLoadMoreVisible: true
    }
  },
  created () {
    if (this.logs) {
      this.localLogs = this.logs
      this.isLoadMoreVisible = false
    } else {
      this.getLogs()
    }
  },
  computed: {
    /**
     * Sorted logs by created_at DESC
     */
    sortedLogs () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.localLogs.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at)
      })
    }
  },
  methods: {
    /**
     * Get logs
     */
    async getLogs () {
      if (!this.campaignId) return false

      this.loader = true
      try {
        const logs = await WatcherLogsService.getWatchersLogs({
          watcher_id: this.watcherId,
          page: this.page
        })
        this.localLogs = [...this.localLogs, ...logs.data]
        this.isLoadMoreVisible = logs.meta.current_page !== logs.meta.last_page
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Load more
     */
    loadMore () {
      this.page++
      this.getLogs()
    },
    /**
     * Parse the expected field given watcher
     */
    parseExpectedValue (value) {
      if (this.watcher.type === 'CAMPAIGN') {
        if (this.watcher.metric === 'SPENDING_RATE') return this.$options.filters.formatMoney(value / 100)
      }
      return value
    },
    /**
     * Parse the expected field given watcher
     */
    parseValue (value) {
      if (this.watcher.type === 'CAMPAIGN') {
        if (this.watcher.metric === 'SPENDING_RATE') return this.$options.filters.formatMoney(value / 100)
      }
      return value
    },
    /**
     * Reset and reload
     */
    reset () {
      this.page = 1
      this.localLogs = []
      this.getLogs()
    }
  }
}

</script>
