import axios from '@/plugins/axios'

/**
 * Get watcher logs
 */
const getWatcherLogs = async ({
  // eslint-disable-next-line camelcase
  watcher_id = null,
  page = 1
}) => {
  try {
    const params = {
      page: page
    }
    // eslint-disable-next-line camelcase
    if (watcher_id) params.watcher_id = watcher_id

    // eslint-disable-next-line camelcase
    const response = await axios.get('admin/watcher-logs', {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  getWatcherLogs
}
