var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"watcher-logs-list-table"},[(_vm.loader && _vm.localLogs.length === 0)?_c('div',{staticClass:"text-center"},[_c('Loader',{attrs:{"size":'big'}})],1):[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table align-middle table-row-dashed fs-6 gy-5",attrs:{"id":"kt_ecommerce_products_table"}},[_vm._m(0),_c('tbody',{staticClass:"fw-semibold text-gray-600"},_vm._l((_vm.sortedLogs),function(log,index){return _c('tr',{key:index},[_c('td',[_c('span',[_vm._v(_vm._s(log.created_at))])]),_c('td',[_c('span',[_vm._v(_vm._s(_vm.parseExpectedValue(log.expected_value)))])]),_c('td',[_c('span',[_vm._v(_vm._s(_vm.parseValue(log.value)))])]),_c('td',[_c('span',{staticClass:"badge",class:{
                  'badge-primary': log.status === 'LOW',
                  'badge-warning': log.status === 'MEDIUM',
                  'badge-danger': log.status === 'HIGH',
                  'badge-secondary': log.status === 'PENDING'
                }},[_vm._v(" "+_vm._s(log.status)+" ")])])])}),0)])]),_c('div',{staticClass:"text-center mt-4"},[(_vm.isLoadMoreVisible)?_c('Button',{staticClass:"btn-primary",attrs:{"loading":_vm.loader},on:{"click":_vm.loadMore}},[_vm._v(" Carregar mais ")]):_vm._e()],1)]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticClass:"text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0"},[_c('th',{staticClass:"min-w-100px"},[_vm._v("Data")]),_c('th',{staticClass:"min-w-70px"},[_vm._v("Valor esperado")]),_c('th',{staticClass:"min-w-70px"},[_vm._v("Valor")]),_c('th',{staticClass:"min-w-70px"},[_vm._v("Status")])])])
}]

export { render, staticRenderFns }