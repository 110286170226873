<template>
    <div class="watcher-logs-chart">
      <div v-if="loader" class="text-center">
        <Loader
          :size="'big'"
        />
      </div>
      <template v-else>
        <LineChartGenerator
          :chart-options="{
              responsive: true,
              maintainAspectRatio: false
          }"
          :chart-data="chartData"
          chart-id="chart"
          dataset-id-key="label"
          :height="350"
        />
      </template>
    </div>
  </template>

<script>

import moment from 'moment'

import Loader from '@/components/common/Loader/Loader'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'

import { Line as LineChartGenerator } from 'vue-chartjs/legacy'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)

export default {
  name: 'WatcherLogChart',
  props: ['logs', 'watcher'],
  components: {
    LineChartGenerator,
    Loader
  },
  data () {
    return {
      localLogs: [],
      loader: false
    }
  },
  created () {
    if (this.logs) {
      const logs = JSON.parse(JSON.stringify(this.logs))
      logs.sort((a, b) => {
        return new Date(a.created_at) - new Date(b.created_at)
      })
      this.localLogs = logs
    }
  },
  computed: {
    chartData () {
      if (!this.logs) return []

      return {
        labels: this.chartLabels,
        datasets: this.chartDatasets
      }
    },
    /**
     * Create datasets from logs
     */
    chartDatasets () {
      if (!this.logs) return []
      const expected = {
        label: 'Valor esperado',
        backgroundColor: '#161711',
        borderColor: '#161711',
        data: this.localLogs.map(item => {
          return this.parseExpectedValue(item.expected_value, false)
        })
        // yAxisID: 'y'
      }

      const values = {
        label: 'Valor efetivo',
        backgroundColor: '#FED615',
        borderColor: '#FED615',
        data: this.localLogs.map(item => {
          return this.parseValue(item.value, false)
        })
        // yAxisID: 'y1'
      }

      return [
        expected,
        values
      ]
    },
    /**
     * Get labels from days of summary
     */
    chartLabels () {
      if (!this.logs) return []

      return this.localLogs.map(item => {
        return moment(item.created_at).format('DD/MM/YYYY')
      })
    }
  },
  methods: {
    /**
     * Parse the expected field given watcher
     */
    parseExpectedValue (value, shouldFormat = true) {
      if (this.watcher.type === 'CAMPAIGN') {
        if (this.watcher.metric === 'SPENDING_RATE') return shouldFormat ? this.$options.filters.formatMoney(value / 100) : value / 100
      }
      return value
    },
    /**
     * Parse the expected field given watcher
     */
    parseValue (value, shouldFormat = true) {
      if (this.watcher.type === 'CAMPAIGN') {
        if (this.watcher.metric === 'SPENDING_RATE') return shouldFormat ? this.$options.filters.formatMoney(value / 100) : value / 100
      }
      return value
    }
  }
}

</script>
